<template>
    <div>
        <card-header title="New Trip Note" icon="fa-plus"/>

        <card-body>
            <trip-note-form :note="newNote"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newNote.subject" @click="submit"><i class="fas fa-plus mr-3"></i>New Note</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";
    import TripNoteForm from "@/cards/trips/NoteForm";

    import {client as http} from '../../http_client';

    export default {
        props: ['card', 'props'],
        components: {TripNoteForm, CardBody, CardFooter, CardHeader},
        data: function () {
            return {
                newNote: {},
                question: {}
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/notes', {trip_id: this.props.tripId, ...this.newNote}, {force: true}).then(response => {
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$reloadCard('trip-notes', {tripId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new note? Unsaved changes will be lost.');
        }
    };
</script>
